window.addEventListener('load', () => {
    productTimer1();
});
window.productTimer1 = function productTimer1() {
    setInterval(onTimerElapsed1, 4000);
}


window.onTimerElapsed1 = function onTimerElapsed1() {
    var dottestimonials1 = document.getElementById("dottestimonials-1");
    var dottestimonials2 = document.getElementById("dottestimonials-2");
    var dottestimonials3 = document.getElementById("dottestimonials-3");
    var dottestimonials4 = document.getElementById("dottestimonials-4");
    var dottestimonials5 = document.getElementById("dottestimonials-5");

    var isPaused = false;

    // console.log('isPaused => ', window.isPaused);

    if (!window.isPaused) {

        var firstdiv = document.getElementById("testimonial_list").firstElementChild;
        var lastdiv = document.getElementById("testimonial_list").lastElementChild;

        var listtestimonials = document.getElementById("testimonial_list"); // Get the <ul> element to insert a new node

        // listtestimonials.children[1].classList.contains('testimonialtext');

        lastdiv.insertAdjacentElement("afterend", firstdiv);

        var currentId1 = listtestimonials.children[1].id;
        // document.getElementById("testimonial-1").classList.add('testimonial--text');

        if (currentId1 === 'testimonial-1') {

            dottestimonials1.classList.add('dotactivetest');
            dottestimonials2.classList.remove('dotactivetest');
            dottestimonials3.classList.remove('dotactivetest');
            dottestimonials4.classList.remove('dotactivetest');
            dottestimonials5.classList.remove('dotactivetest');


        } else if (currentId1 === 'testimonial-2') {


            dottestimonials1.classList.remove('dotactivetest');
            dottestimonials2.classList.add('dotactivetest');
            dottestimonials3.classList.remove('dotactivetest');
            dottestimonials4.classList.remove('dotactivetest');
            dottestimonials5.classList.remove('dotactivetest');

        } else if (currentId1 === 'testimonial-3') {


            dottestimonials1.classList.remove('dotactivetest');
            dottestimonials2.classList.remove('dotactivetest');
            dottestimonials3.classList.add('dotactivetest');
            dottestimonials4.classList.remove('dotactivetest');
            dottestimonials5.classList.remove('dotactivetest');
        } else if (currentId1 === 'testimonial-4') {

            dottestimonials1.classList.remove('dotactivetest');
            dottestimonials2.classList.remove('dotactivetest');
            dottestimonials3.classList.remove('dotactivetest');
            dottestimonials4.classList.add('dotactivetest');
            dottestimonials5.classList.remove('dotactivetest');

        } else if (currentId1 === 'testimonial-5') {


            dottestimonials1.classList.remove('dotactivetest');
            dottestimonials2.classList.remove('dotactivetest');
            dottestimonials3.classList.remove('dotactivetest');
            dottestimonials4.classList.remove('dotactivetest');
            dottestimonials5.classList.add('dotactivetest');
        }
    }

}
window.changetestimonial = function changetestimonial(currentId1) {
    var dottestimonials1 = document.getElementById("dottestimonials-1");
    var dottestimonials2 = document.getElementById("dottestimonials-2");
    var dottestimonials3 = document.getElementById("dottestimonials-3");
    var dottestimonials4 = document.getElementById("dottestimonials-4");
    var dottestimonials5 = document.getElementById("dottestimonials-5");

    var isPaused = false;

    var listtestimonials = document.getElementById("dottestimonials"); // Get the <ul> element to insert a new node

    // listtestimonials.addEventListener("click", function (e) {
    //     if (e.target && e.target.matches("li.item")) {
    //         e.target.className = "foo"; // new class name here
    //         alert("clicked " + e.target.innerText);
    //     }
    // });

    window.isPaused = true;



    var firstdiv = document.getElementById("testimonial_list").firstElementChild;

    var testimonial1 = document.getElementById("testimonial-1");
    var testimonial2 = document.getElementById("testimonial-2");
    var testimonial3 = document.getElementById("testimonial-3");
    var testimonial4 = document.getElementById("testimonial-4");
    var testimonial5 = document.getElementById("testimonial-5");



    setTimeout(() => {
        window.isPaused = false;
    }, 15000);

    if (currentId1 === 'dottestimonials-1') {

        firstdiv.insertAdjacentElement("afterend", testimonial2);

        testimonial2.insertAdjacentElement("afterend", testimonial1);
        testimonial1.insertAdjacentElement("afterend", testimonial3);
        testimonial3.insertAdjacentElement("afterend", testimonial4);
        testimonial4.insertAdjacentElement("afterend", testimonial5);

        dottestimonials2.classList.remove('dotactivetest');
        dottestimonials3.classList.remove('dotactivetest');
        dottestimonials4.classList.remove('dotactivetest');
        dottestimonials5.classList.remove('dotactivetest');
        dottestimonials1.classList.add('dotactivetest');

    } else if (currentId1 === 'dottestimonials-2') {


        firstdiv.insertAdjacentElement("afterend", testimonial1);

        testimonial1.insertAdjacentElement("afterend", testimonial2);
        testimonial2.insertAdjacentElement("afterend", testimonial3);
        testimonial3.insertAdjacentElement("afterend", testimonial4);
        testimonial4.insertAdjacentElement("afterend", testimonial5);

        dottestimonials1.classList.remove('dotactivetest');
        dottestimonials2.classList.add('dotactivetest');
        dottestimonials3.classList.remove('dotactivetest');
        dottestimonials4.classList.remove('dotactivetest');
        dottestimonials5.classList.remove('dotactivetest');
    } else if (currentId1 === 'dottestimonials-3') {

        firstdiv.insertAdjacentElement("afterend", testimonial1);

        testimonial1.insertAdjacentElement("afterend", testimonial3);
        testimonial3.insertAdjacentElement("afterend", testimonial4);
        testimonial4.insertAdjacentElement("afterend", testimonial5);
        testimonial5.insertAdjacentElement("afterend", testimonial2);

        dottestimonials1.classList.remove('dotactivetest');
        dottestimonials2.classList.remove('dotactivetest');
        dottestimonials3.classList.add('dotactivetest');
        dottestimonials4.classList.remove('dotactivetest');
        dottestimonials5.classList.remove('dotactivetest');
    } else if (currentId1 === 'dottestimonials-4') {

        firstdiv.insertAdjacentElement("afterend", testimonial3);

        testimonial3.insertAdjacentElement("afterend", testimonial4);
        testimonial4.insertAdjacentElement("afterend", testimonial5);
        testimonial5.insertAdjacentElement("afterend", testimonial1);
        testimonial1.insertAdjacentElement("afterend", testimonial2);

        dottestimonials1.classList.remove('dotactivetest');
        dottestimonials2.classList.remove('dotactivetest');
        dottestimonials3.classList.remove('dotactivetest');
        dottestimonials4.classList.add('dotactivetest');
        dottestimonials5.classList.remove('dotactivetest');
    } else if (currentId1 === 'dottestimonials-5') {

        firstdiv.insertAdjacentElement("afterend", testimonial4);

        testimonial4.insertAdjacentElement("afterend", testimonial5);
        testimonial5.insertAdjacentElement("afterend", testimonial3);
        testimonial3.insertAdjacentElement("afterend", testimonial2);
        testimonial2.insertAdjacentElement("afterend", testimonial1);

        dottestimonials1.classList.remove('dotactivetest');
        dottestimonials2.classList.remove('dotactivetest');
        dottestimonials3.classList.remove('dotactivetest');
        dottestimonials4.classList.remove('dotactivetest');
        dottestimonials5.classList.add('dotactivetest');
    }
}